<template>
  <span class="hidden max-md:block">Filtern & Sortieren {{ activeFilterString }}</span>
  <span class="block max-md:hidden">Filtern {{ activeFilterString }}</span>
</template>

<script setup>
  import { useStore } from '@nanostores/vue'
  import { listingFilters } from '@store/listingFilterStore.js'
  import { computed } from 'vue'

  const activeFilters = useStore(listingFilters);

  const activeFilterString = computed(() => {
    const filters = activeFilters.value;
    let activeCount = 0;

    for (const key in filters) {
      if (filters[key]) {
        const values = filters[key].values;
        if (Array.isArray(values)) {
          if (key === 'price-filter') {
            activeCount += values.length > 0 ? 1 : 0; // price-filter maximum counts as 1
          } else {
            activeCount += values.length;
          }
        }
      }
    }

    return activeCount > 0 ? `(${activeCount})` : '';
  });
</script>
